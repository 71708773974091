<template>
	<div class="list list--feedbacks list-feedbacks">
		<table-list
			:items="feedbacks"
			:loading="isLoading"
		>
			<template slot="header">
				<span
					:class="`feedbacks-header-item feedbacks-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`feedbacks-item feedbacks-item--${key} feedbacks-item--${item['status']}`"
					:key="`item_${key}`"
					:to="{ name: 'feedback', params: { feedbackId: item.feedbackId } }"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
				>
				</router-link>
			</template>

		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'

import TableList from '@/components/TableList'
import { FeedbackType, FeedbackPlatform, FeedbackStatus } from '@/lib/enums'

export default {
	name: 'ListFeedbacks',
	components: {
		TableList,
	},
	props: {
		feedbacks: {
			type: Array,
			default: () =>([]),
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		columns: {
			type: 'Type',
			summary: 'Summary',
			appVersion: 'App Version',
			platformDevice: 'Platform',
			// version: 'Version',
			// brandDevice: 'Device',
			// device: 'Device',
			dateCreated: 'Date',
			status: 'Status',
			// profileName: 'Name',
			// email: 'Email',
			// feedback: 'Client',
			// dateLogin: 'Last Login',
			// status: 'Status',
		},
		order: 'asc',
	}),
	computed: {
		// isLoading() {
		// 	return this.$store.getters['misc/loading']
		// },
		query() {
			return this.$route.query
		},
		orderBy() {
			let orderBy = {
				// nameEmail: 'firstName',
				// dateCreated: 'dateCreated',
				// organization: 'organization.name',
				// 'settings.salesforceId': 'salesforceId',
				// dateLogin: 'dateLogin',
				// status: 'status',
			}

			return orderBy
		},
	},
	methods: {
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					try {
						value = `${format(value, 'PPp')}`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'platform':
					return FeedbackPlatform.match(value)
				case 'type':
					return FeedbackType.match(value)
				case 'status':
					return FeedbackStatus.match(value)
			}
			return value
		},
		sort(key) {

		},
		get,
	}
}
</script>

<style scoped lang="scss">
.list-feedbacks {
	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('border', 'light');
			display: grid;
			grid-template-columns: minmax(80px, 1fr) minmax(150px, 1.5fr) repeat(auto-fit, minmax(100px, 1fr));
			// grid-template-columns: minmax(80px, 0.75fr) minmax(100px, 1.5fr) repeat(4, minmax(100px, 1fr)) 0.5fr;

			@include modules.media-query('phone') {
				grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
			}
		}

		.list-feedbacks__app-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	.feedbacks-header-item {
		@include modules.fontSize(14px);
		align-items: center;
		display: flex;
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.feedbacks-item {
		color: modules.color_('text', 'medium');
		font-weight: 500;
		overflow-wrap: break-word;
		padding: 1.25em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--unread {
			color: modules.color_('text', 'dark');
			font-weight: 600;
		}

		&--pending {
			&.feedbacks-item--status {
				color: modules.color_('alert', 'warn');
			}
		}

		&--removed {
			opacity: 0.7;

			&.feedbacks-item--status {
				color: modules.color_('alert', 'error')
			}
		}
	}
}
</style>
