<template>
	<div class="partial partial--feedbacks feedbacks">
		<div class="partial__heading">
			<h1 class="partial__title">Feedback</h1>
			<div class="partial__actions">
			</div>
		</div>
		<div class="partial__body">
			<card>
				<list-feedbacks :feedbacks="feedbacks" :isLoading="isLoading" />
				<pagination :count="feedbacks.length" :limit="limit" />
			</card>
		</div>
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListFeedbacks from '@/components/ListFeedbacks'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

export default {
	name: 'PartialFeedbacks',
	components: {
		Card,
		ListFeedbacks,
		Modal,
		Pagination,
	},
	data: () => ({
		orderBy: 'dateCreated',
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'feedback'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		feedbacks() {
			return this.$store.getters['feedback/feedbacks'].map( f => {
				f.platformDevice = [f.brandDevice, f.platformVersion].join('<br />')
				return f
			})
		},
		limit() {
			return this.$route.query.limit || 20
		},
	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.back()
			}
		},
	},
	metaInfo() {
		return {
			title: this.modalTitle || `Feedback`,
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(to, from) {
				if ((to && to.name == 'feedbacks') && (from && from.name == 'feedback')) return
				const { orderBy, startAt, } = to.query
				this.$store.dispatch('feedback/list', {orderBy, limit: this.limit, startAt, })
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
